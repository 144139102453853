body {
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
  margin: 0;
  height: 100%;
  width: 100%;
}
.clrAmber{
 color: #FFBF00;
}
.clrGreen{
  color: #007F00;
  font-weight: bold;
 }
.loader {
  height: 100vh;
  width: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
.loader .spinner-border {
  position: absolute;
  top: 40%;
  left: 50%;
}
.App-logo {
  height: 20vmin;
  pointer-events: none;
}
.text-muted {
  font-size: 87.5%;
}
.lblchangecolor{
  color: #007F00;
  font-size: 13px;
  font-weight: bold;
}

.changecolor {
  color: black;
  /* font-family:'Roboto-regular'; */
  margin: 10px;
}

.lblsetmargin {
  margin-top: 12px;
}

.App-header {
  background-color: #fbfbfb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;

}

.Sign-in-via-bp-connection {
  margin: 0 1px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.1px;
  text-align: center;
  color: #002bbc;
  text-decoration: none;
}

.App-headerlogo {
  height: 50px;
}

.App-Logoutlogo {
  height: 30px;
  cursor: pointer;
}
.mobilefiltericon
{
  cursor: pointer;
}
.searchicon
{
  cursor: pointer;
}
.mobileHistoryicon
{
  cursor: pointer;
  margin-right: 20px;
}
.marginbtm
{
  margin-bottom: 15px;
}
.coldays{
  padding: 10px;
}

.fixed-header {
  position: fixed;
  width: 100%;
}

.headerheight {
  height: 70px;
}
.card-body
{
  line-height: 2;
  font-size: 14px;
}
.alignright{
  text-align: right;
  padding-top: 20px;
}
.paddingtopsitename
{
  padding-top: 10px;
}
.alignrightforupdatetime{
  text-align: right;
}
.divOrdersTable {
  overflow-x: scroll;
  /* min-height: 550px;
  height: 550px; */
  padding-left: 10px;
  padding-right: 10px;
  height: calc(100vh - 250px);
}
.divOrdersTablem {
  overflow-x: scroll;
  /* min-height: 550px;
  height: 550px; */
  padding-left: 10px;
  padding-right: 10px;
  height: calc(100vh - 300px);
}
.floatleft{
  margin-right: 50px;
}
.paddingside{
  padding-left: 30px;
  padding-right: 30px;
}
.floatright{
  float: right;
}
.rowCenter{
  text-align: center;
}

/* .txtcolor {
  margin: 25px;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: 0.16px;
  color: #007f00;
} */

.You-are-not-signed-i {
  width: 293px;
  height: 28px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(17, 17, 17, 0.64);
  margin-top: 19px;
  margin-bottom: -5px;
}

.Truck-Load-Visibilit {
  width: 182px;
  height: 30px;
  margin: 19px 0 0;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: center;
  color: #007f00;
}

.rowWidth {
  width: 100%;
  padding-left: 24px;
  padding-top: 10px;
  background-color: white;
}

.btnOrderdetailsreturn {
  text-decoration: none;
  letter-spacing: 0.15px;
}
.divmarginnpadding{
  padding-top: 5px;
  margin-left: 20px;
}

.divcolordetails {
  background-color: white;
  height: 39px;
}

.MuiPaper-rounded {
  margin: 10px;
}

.chipmargin {
  margin-left: 20px;
  margin-top: 16px;
}

.footer {
  height: 50px;
  bottom: 0;
  background-color: white;
  position: inherit;
  width: 100%;
  z-index: 1;

}

.btnhide {
  display: none;
}

.rowdetails {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.page-wrap {
  min-height: 100vh;
  background: #dedede;
}

.rowspacingleft {
  margin-left: -41px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Statusiconmargin {
  margin-right: 10px;
}

.divOrder {
  overflow: hidden;
  background-color: #f2f2f2;
}

/* .container{
  max-width: 100%;
  padding-right: 20px;
} */
.contain {
  padding: 16px;
}

.wrap {
  display: flow-root;
  background: white;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 7px 7px 30px -5px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.wrapmap {
  display: flow-root;
  background: white;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 7px 7px 30px -5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}


.mbr-iconfont {
  font-size: 4.5rem !important;
  color: #313131;
  margin: 1rem;
  padding-right: 1rem;
}

.mbr-section-title3 {
  text-align: left;
}

h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.display-5 {
  font-size: 1.1rem;
}

.mbr-bold {
  font-weight: 700;
}

table {
  border-collapse: collapse;
}

.display-6 {
  font-size: 1rem;
}

.Desktop {
  width: 100%;
  background-color: #f2f2f2;
}

.txtcolor {
  color: #007f00;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .16px;
  line-height: 1.94;
  margin: 25px;
}

.background {
  background-color: #fff;
  border-bottom: 1px solid #dad0d0;
}

.headerbgcolor {
  background-color: #fff;
}

.Orders {
  width: 61px;
  height: 30px;
  margin: 0 687px 8px 20px;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #111;
}

.Rectangle {
  padding: 15px;
  background-color: #fff;
}

.divDays {
  width: 39px;
  height: 21px;
  margin: 0 8px 0 0;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.15px;
  color: #111;
}

.divdate {
  width: 73px;
  height: 21px;
  margin: 0 0 0 8px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.64);
}

.table>thead {
  color: rgba(17, 17, 17, 0.64);
}

tbody {
  line-height: 3.43;
}

.bgcolor {
  margin: 0 0 0 4px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.2px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.bgtbodycolor {
  margin: 0 0 0 4px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.2px;
  background-color: #fff;
}

.table>:not(:first-child) {
  border-top: currentColor;
}

.divpadding {
  padding-top: 20px;
}

.tdbold {
  font-weight: bold;
  /* width: 22%; */
}
/* .tdwidth {
  width: 22%;
}

.tdwidthorder {
  width: 12%;
} */
.tdwidthstatus {
  width: 14%;
}
.sidenumberwidth{width: 10%;}

.divFilterbtnpadding {
  padding-top: 8px;
  /* text-align: right;
 margin-right: 25px; */
}
.divFilterbtnpaddingm {
  padding-top: 8px;
  text-align: center;

}
.has-search
{
  margin-top: 5px;
}
.petaorder{
  font-size: 12px;
  margin-left: 18px;
  /* text-align: center; */
}
.btnmarginright {
  margin-right: 10px;
  /* margin-left: 20px; */
}

.btn-secondary {
  color: black;
  background-color: #dee1e4;
  border-color: #abb1b5;
}

.box {
  display: inline-block;
  height: auto;
  width: 20px;
}

.tddiv {
  line-height: 1.77;
  color: rgba(17, 17, 17, 0.84);
  cursor: pointer;
}

.trDays {
  background: #f2f2f2;
  border-bottom: hidden;
}

.mobile {
  display: none;
}
.searchclose {
  display: none;
}
.disblock{
  display: block;
  margin-left: 47px;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .mobileicon{
    display: block;
  }
  .lbluserId{
    padding-left: 0px;
    font-size: 5px;
    color: green;
  }
 
}
@media only screen 
and (min-width : 1224px) {
  .lbluserId{
    font-size: 11px;
    color: green;
  }
}