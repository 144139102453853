.modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.modal-content{
     width: 350px;
    background-color: #fff;
    /* min-height: 400px; */
}
.modal-header{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}
 .modal-footer{
    
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}
.modal-title
{
    margin: 0;
    font-size: 20px;
}
.modal-title-inner
{
    font-size: 14px;
    font-weight: bold;
}
.modal-body{
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.custom-control-label{
    font-size: 13px;
    padding: 4px;
   
}
.form-group{
    padding-bottom: 15px;
}
.custom-control-input{
    width: 20px;
    height: 20px;
}
.btn-outline-secondary{
    width: 140px;
}
.divoverflow {
    height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
 .rowmodal{
    padding-bottom: 15px;
 }